<template>
  <div class="app">
    <!-- 新增线索弹窗 -->
    <el-drawer
      v-model="addDrawer"
      :close-on-click-modal="true"
      :with-header="false"
      size="41%"
    >
      <div class="tab">
        <div style="z-index: 1029;margin-top: 10px;position: fixed;left: 92.5%" v-if="!dis" :class="{cancelBtn: activeName !== 'cule'}">
          <el-button
            type="success"
            @click="saveCule"
            size="small"
            :loading="saveLoading"
            v-if="activeName == 'cule'"
            >保存</el-button
          >
          <el-button
            type="primary"
            @click="cancel"
            size="small"
            >取消</el-button
          >
        </div>
        <div style="z-index: 1029;margin-top: 10px;position: fixed;left: 96%" v-if="dis">
          <el-button
            type="primary"
            @click="cancel"
            size="small"
            >取消</el-button
          >
        </div>
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" style="width: 100%;">
          <el-tab-pane label="线索信息" name="cule">
            <el-form :model="form" :rules="rules" ref="form" label-width="120px" :inline="true">
              <!-- 基础信息 -->
              <div style="margin-top: 10px;">
                <div class="formTitle">
                  基础信息
                </div>
                <div>
                  <el-form-item label="客户名称:" prop="customerName">
                    <el-input v-model="form.customerName" :disabled="dis" placeholder="请输入客户名称" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="跟进负责人:">
                    <selectuser code="addCustomer" :disabled="dis" v-model:userId="form.followUserId" :multiples="false" style="width: 190px;margin: 0;" ></selectuser>
                  </el-form-item>
                  <el-form-item label="客户联系人:">
                    <el-input v-model="form.customerContacter" :disabled="dis" placeholder="请输入客户联系人" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="联系人手机:" prop="customerContacterMobile">
                    <el-input v-model="form.customerContacterMobile" :disabled="dis" placeholder="请输入联系人手机" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="客户来源:">
                    <el-select
                      v-model="form.customerSource"
                      placeholder="请选择客户来源"
                      filterable
                      size="small"
                      style="width: 190px; margin: 0"
                      :disabled="dis"
                    >
                      <el-option
                        v-for="item in customerStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="来源说明:">
                    <el-input v-model="form.sourceComment" :disabled="dis" placeholder="请输入来源说明" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="意向服务类型:">
                    <el-radio-group v-model="form.serviceType" :disabled="dis" style="width: 190px; margin: 0">
                      <el-radio :label="1">代账</el-radio>
                      <el-radio :label="2">增值</el-radio>
                    </el-radio-group>
                    <!-- <el-checkbox-group v-model="form.serviceType" style="width: 200px; margin: 0">
                      <el-checkbox v-for="item in options" :key="item.value" :label="item.value">  
                        {{ item.label }}  
                      </el-checkbox>  
                    </el-checkbox-group> -->
                  </el-form-item>
                  <el-form-item label="线索等级:">
                    <select-clue
                      v-model:clueStatus="form.level"
                      placeholder="请选择"
                      :type="'level'"
                      style="width: 190px; margin: 0"
                      :disabled="dis"
                    />
                  </el-form-item>
                  <el-form-item label="备注:">
                    <el-input v-model="form.remark" :disabled="dis" placeholder="请输入备注" size="small" style="width: 532px; margin: 0" clearable />
                  </el-form-item>
                </div>
              </div>

              <!-- 企业信息 -->
              <div style="margin-top: 10px;">
                <div class="formTitle">
                  企业信息
                </div>
                <div>
                  <el-form-item label="所在地区:">
                    <!-- <addressList v-model:type="form.customerDistrict" :disabled="dis" style="width:190px;margin: 0;"></addressList> -->
                    <districtList v-model:modelValue="form.customerDistrict" :disabled="dis" style="width: 190px; margin: 0"></districtList>
                  </el-form-item>
                  <el-form-item label="办公地址:">
                    <el-input v-model="form.customerAddress" :disabled="dis" placeholder="请输入办公地址" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="法定代表人:">
                    <el-input v-model="form.legalPerson" :disabled="dis" placeholder="请输入法定代表人" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="成立日期:">
                    <el-date-picker
                      style="width:190px;"
                      size="small"
                      v-model="form.incorporationTime"
                      type="date"
                      placeholder="请选择成立日期"
                      clearable
                      value-format="YYYY-MM-DD"
                      :disabled="dis"
                    />
                  </el-form-item>
                  <el-form-item label="注册资本:">
                    <el-input v-model="form.registeredCapital" :disabled="dis" placeholder="请输入注册资本" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="纳税人资格:">
                    <el-select
                      v-model="form.taxpayerRight"
                      placeholder="请选择纳税人资格"
                      style="width: 190px; margin: 0"
                      filterable
                      size="small"
                      :disabled="dis"
                    >
                      <el-option
                        v-for="item in taxpayerList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="统一社会信用代码:">
                    <el-input v-model="form.socialCode" :disabled="dis" placeholder="请输入统一社会信用代码" size="small" class="w200" clearable />
                  </el-form-item>
                  <el-form-item label="国家标准行业:">
                    <selectindustry v-model:modelValue="form.standardIndustry" :disabled="dis" style="width: 190px; margin: 0"></selectindustry>
                  </el-form-item>
                  <el-form-item label="经营范围:">
                    <el-input v-model="form.businessScope" :disabled="dis" placeholder="请输入经营范围" size="small" style="width: 532px; margin: 0" clearable />
                  </el-form-item>
                </div>
              </div>

              <!-- 系统信息 -->
              <div style="margin-top: 10px;">
                <div class="formTitle">
                  系统信息
                </div>
                <div>
                  <el-form-item label="线索状态:">
                    <el-select
                      v-model="form.status"
                      placeholder="请选择"
                      style="width: 190px; margin: 0"
                      filterable
                      size="small"
                      class="w200"
                      disabled
                    >
                      <el-option
                        v-for="item in culeStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="跟进阶段:">
                    <el-select
                      v-model="form.followStage"
                      placeholder="请选择"
                      style="width: 190px; margin: 0"
                      filterable
                      size="small"
                      class="w200"
                      disabled
                      v-if="form.followStage != '1'"
                    >
                      <el-option
                        v-for="item in followStatusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                    <el-input v-model="form.user" size="small" class="w200" clearable disabled v-else/>
                  </el-form-item>
                  <el-form-item label="最近分配时间:">
                    <el-input v-model="form.assignTime" size="small" class="w200" clearable disabled />
                  </el-form-item>
                  <el-form-item label="上次释放时间:">
                    <el-input v-model="form.lastReleaseTime" size="small" class="w200" clearable disabled />
                  </el-form-item>
                  <el-form-item label="录入人:">
                    <el-input v-model="form.inputClerkName" size="small" class="w200" clearable disabled />
                  </el-form-item>
                  <el-form-item label="录入时间:">
                    <el-input v-model="form.createdTime" size="small" class="w200" clearable disabled />
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="跟进记录" name="follow">
            <!-- 有数据 -->
            <div v-if="followList && addDrawer" style="margin: 20px;">
              <el-timeline>
                <el-timeline-item
                  v-for="(item, index) in followList"
                  :key="index"
                  placement=top
                >
                  <p>跟进内容：{{ item.content }}</p>
                  <p>跟进时间：{{ item.createdTime }}</p>
                  <p>跟进人：{{ item.userName }}</p>
                  <p>拜访方式：{{ followVisit.find(val => val.value == item.visitMethod).label }}</p>
                  <p>跟进阶段：{{ item.followStage }}</p>
                  <p v-if="item.nextTime">下次跟进时间：{{ item.nextTime }}</p>
                </el-timeline-item>
              </el-timeline>
            </div>

             <!-- 无数据 -->
             <div v-else>
              <el-empty description="暂无数据" />
            </div>
          </el-tab-pane>
          <el-tab-pane label="操作记录" name="operate">
            <!-- 有数据 -->
            <div v-if="operateList && addDrawer" style="margin: 20px;">
              <el-timeline>
                <el-timeline-item
                  v-for="(item, index) in operateList"
                  :key="index"
                  :timestamp="item.createdTime"
                >
                  {{ operateTypeList.find(i => i.value == item.operation).label }}操作--{{ item.cnName }}
                </el-timeline-item>
              </el-timeline>
            </div>

            <!-- 无数据 -->
            <div v-else>
              <el-empty description="暂无数据" />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { CUSTOMER_STATUS,CULE_GRADE,TRAXPAYER,CULE_STATUS,FOLLOW_STATUS,CLUELOG,FOLLOW_VISIT } from "@/utils/commonData";
import addressList from "@/components/Screening/address";
import districtList from "@/components/Screening/district";
import { addClue,operation,followList } from "@/api/newCrm.js";
import selectindustry from "./selectIndustry.vue"

export default {
  name: 'infoForm',
  components: {
    addressList,
    selectindustry,
    districtList
  },
  props: {
    // formTemp: {
    //   type: Object,
    //   default: {}
    // },
    dis:{
      type: Boolean,
      default: false
    }
  },
  data () {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入联系人手机"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      saveLoading:false,
      form: {},//表单数据
      customerStatusList: CUSTOMER_STATUS.filter(item => item.label !== "全部"),//客户来源
      culeGradeList: CULE_GRADE,//线索等级
      culeStatusList: CULE_STATUS,//线索状态
      followStatusList: FOLLOW_STATUS,//跟进状态
      followVisit: FOLLOW_VISIT,
      taxpayerList: TRAXPAYER,//纳税人资格
      rules: {
        customerName: [
          { required: true, message: '请输入客户名称', trigger: 'blur' },
        ],
        customerContacterMobile: [
        { required: true, validator: validateMobile, trigger: "blur" }
        ],
      },
      options: [//意向服务类型
        { label: "代账", value: 1 },
        { label: "增值", value: 2 },
      ],
      addDrawer: false,
      activeName:'cule',//tab标签
      operateList: [],//操作记录
      followList: [//跟进记录
      ],
      operateTypeList: CLUELOG,
    }
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {},
  methods: {
    // 初始化
    init(val,type){
      this.form ={}
      this.operateList = []
      this.followList = []
      this.activeName = 'cule'
      this.form = Object.assign({},val);
      if(this.form.followUserId == 0 ){
        this.form.followUserId =null
      }
      if(this.form.customerSource == 0){
        this.form.customerSource = null
      }
      if(this.form.taxpayerRight == 0){
        this.form.taxpayerRight = null
      }
      this.addDrawer = true
      if(type == 'follow'){
        this.activeName = 'follow'
        followList({ clueId: this.form.id }).then(res => {
          if (res.data.msg == 'success') {
            this.followList = res.data.data.data
          }
        })
      }
    },
    saveCule(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.form.followUserId){
            this.form.followUserId = this.form.followUserId*1
          }
          this.saveLoading = true
          addClue(this.form).then(res => {
            this.saveLoading = false
            if (res.data.msg == 'success') {
              this.$qzfMessage("修改成功")
              this.$emit('success')
              this.addDrawer = false
            }
            // this.options = res.data.data.list
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    cancel(){
      this.addDrawer = false
      this.form = {}
    },
    // 点击标签操作
    handleClick(tab){
      this.operateList = []
      this.followList = []
      this.activeName = tab.props.name
      if(tab.props.label =='跟进记录'){
        followList({ clueId: this.form.id }).then(res => {
          if (res.data.msg == 'success') {
            this.followList = res.data.data.data
          }
        })
      }else if(tab.props.label =='操作记录'){
        operation({ clueId: this.form.id }).then(res => {
          if (res.data.msg == 'success') {
            this.operateList = res.data.data.data
          }
        })
      }
    },
  }
}
</script>

<style scoped lang="less">
.formTitle{
  font-weight: bold;
  margin-bottom: 20px;
}
.w200{
  width: 190px;
  margin: 0;
}
.drawerHead{
  display: flex;
  align-items: center;
}
.tab :deep(.el-tabs__nav-wrap){
  width: 100%!important;
}
.tab :deep(.el-button+.el-button) {
    margin-left: 5px!important;
}
.cancelBtn{
  left: 96%!important;
}
</style>
