<template>
  <el-cascader ref="cascaderInstanceRef" v-model="innerValue" :options="options" @change="changeValue" size="small" filterable clearable />
</template>

<script>
import { getBaseDistrict } from '@/api/public'
export default {
  name: '',
  components: {},
  props: {
    modelValue: { type: Number },
  },
  data() {
    return {
      options: [],
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    this.init();
  },
  computed: {
    innerValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit("update:modelValue", val?.[val?.length - 1]);
      },
    },
  },
  methods: {
    init() {
      getBaseDistrict({}).then(res=>{
        this.options = res.data.data.list
        console.log(res);
      })
    },
    changeValue() {
      this.$emit("change");
    },
  }
}
</script>

<style scoped lang="less"></style>
